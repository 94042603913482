import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import Loading from '../Global/Loading';

export class AuthNavigation extends Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const { linkURL, linkText, linkClickFunction, buttonName, arrowIcon, buttonDisable, buttonClickFunction, loading, loadingText } = this.props;

        return (
            <Row className="mb-4">
                <Col className="col-8 order-last text-right align-self-center">
                    {loading
                        ? <Loading loadingText={loadingText} />
                        : <Button
                            type="submit"
                            color="primary"
                            className="text-uppercase px-4 py-2"
                            onClick={buttonClickFunction}
                            disabled={buttonDisable}
                        >
                            {buttonName ? buttonName : ''}
                            {arrowIcon && <span aria-hidden="true">&nbsp;&gt;</span>}
                        </Button>
                    }
                </Col>
                <Col className="col-4 order-first align-self-center">
                    {linkURL &&
                        <Link to={linkURL}>
                            <span aria-hidden="true">&lt;&nbsp;</span>
                            {linkText ? linkText : ''}
                        </Link>
                    }
                    {linkClickFunction &&
                        <Button
                            type="button"
                            color="link"
                            onClick={linkClickFunction}
                        >
                            <span aria-hidden="true">&lt;&nbsp;</span>
                            {linkText ? linkText : ''}
                        </Button>
                    }
                </Col>
            </Row>
        );
    }
}