import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, UncontrolledCollapse } from 'reactstrap';
import { MainFormNavInfo } from './MainFormNavInfo';
import TooltipItem from '../Fields/TooltipItem';
import { CardCaixaIU } from './Product/CardCaixaIU';
import { CaixaDireta } from './Product/CaixaDireta';
import { CardCaixaISIC } from './Product/CardCaixaISIC';
import { CardContinente } from './Product/CardContinente';
import { ContaPoupanca } from './Product/ContaPoupanca';
import { MainFormNavigation } from './MainFormNavigation';
import Loading from '../Global/Loading';
import { ModalForm } from './Modal';
import { ClientQuestionField } from '../Fields/ClientQuestionField';
import { NumberOfClientField } from '../Fields/NumberOfClientField';
import { AccountNumberField } from '../Fields/AccountNumberField';
import { CardNumberField } from '../Fields/CardNumberField';

export class MainFormStep3 extends Component {
    constructor(props) {
      
        super(props);
        this.state = {
            error: true,
            loading: false,
            show: false,
            studentCard: this.props.data.studentCard ? this.props.data.studentCard : false,
            isModalChecked: false,
            modalInputCaller: "",
            isValid: this.props.data.contaCaixaM || this.props.data.ValidUseExistingAccount || (this.props.data.studentCard ? this.props.data.studentCard : false)
                
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleStudentCardChange = this.handleStudentCardChange.bind(this);

        this.handleShowModalContaM = this.handleShowModalContaM.bind(this);
        this.onPoupancaTermsChanged = this.onPoupancaTermsChanged.bind(this);

        this.handleTermsLinkModalContaMClicked = this.handleTermsLinkModalContaMClicked.bind(this);
        this.handleTermsLinkModalContaPoupancaClicked = this.handleTermsLinkModalContaPoupancaClicked.bind(this);
        this.onresetCardStudent = this.onresetCardStudent.bind(this);
        
        this.continue = this.continue.bind(this);
        this.back = this.back.bind(this); 

        this.regexInputNumberClient = /^(\d{9})?$/;
        this.regexInputCardNumber = /^(\d{16})?$/;
        this.regexInputAccountNumber = /^(\d{13})?$/;
    }

    handleShowModalContaM = () => {
        if (this.props.data.contaCaixaM) {
            this.props.data.contaCaixaM = !this.props.data.contaCaixaM
            this.props.data.contaPoupanca = false
            this.setState({
               ...this.state.studentCard,
                studentCard: this.props.data.contaCaixaM ? false : this.state.studentCard
            });
           return
        }

        this.setState({
            modalInputCaller: "contaCaixaM",
            isModalChecked: this.props.data.contaCaixaM,
            show: true 
        });
    };

    handleShowModalUseAccount = () => {
        this.props.data.contaCaixaM = false;
        this.props.data.contaPoupanca = false;
        this.setState((prevstate) => ({
            ...prevstate,
            studentCard: false,
        }));

        this.setState({
            modalInputCaller: "contaCaixaMUseAccount",
            isModalChecked: this.props.data.ValidUseExistingAccount,
            show: true 
        });
    }

    handleTermsLinkModalContaMClicked = () => {
        this.setState({
            modalInputCaller: "contaCaixaM",
            isModalChecked: this.props.data.contaCaixaM,
            show: true
        });
    };

    handleTermsLinkModalContaPoupancaClicked = () => {
        this.setState({
            modalInputCaller: "contaPoupanca",
            isModalChecked: this.props.data.contaPoupanca,
            show: true
        });
    };

   onPoupancaTermsChanged = () => {
        if (this.props.data.contaPoupanca) {
            this.props.data.contaPoupanca = !this.props.data.contaPoupanca
            this.setState({
            });
            return
        }

        this.setState({
            modalInputCaller: "contaPoupanca",
            isModalChecked: this.props.data.contaPoupanca,
            show: true 
        });
    };

    handleClose = (fromModal) => {
        if (this.state.modalInputCaller === "contaCaixaM") {
            this.props.data.contaCaixaM = fromModal.isChecked;
            if(this.props.data.contaCaixaM){
                this.setState( (currState) =>{return({ ...currState, studentCard: false})})
            }
        } 
        
        if(this.state.modalInputCaller === "contaCaixaMUseAccount"){
            this.props.onChangeFunction({name: "ValidUseExistingAccount", value: fromModal.isChecked}, '', '', '');
        }

        if (this.state.modalInputCaller === "contaPoupanca") {
            this.props.onChangeFunction({name: "contaPoupanca", value: fromModal.isChecked}, '', '', '');
        }
        
        this.setState({
            modalInputCaller:"",
            show: false
        });

        this.handleChange()
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onChangeFunction({name: "studentCard", value: false}, '', '', '');
    }

    handleChange() {
        this.setState({
            isValid: this.state.contaCaixaM
        });
    }

    handleStudentCardChange() {
        this.props.data.contaCaixaM = false
        this.props.data.contaPoupanca = false;
        this.props.data.useExistingAccount = false;
        this.props.data.continentCardNumber = null;
        this.props.data.ValidUseExistingAccount = false;


        this.props.onChangeFunction({name: "contaCaixaM", value: false}, '', '', '');
        this.props.onChangeFunction({name: "contaPoupanca", value: false}, '', '', '');
        this.props.onChangeFunction({name: "useExistingAccount", value: false}, '', '', '');
        this.props.onChangeFunction({name: "continentCardNumber", value: null}, '', '', '');
        this.props.onChangeFunction({name: "ValidUseExistingAccount", value: false}, '', '', '');
        this.props.onChangeFunction({name: "cardNumber", value: null}, '', '', '');
        this.props.onChangeFunction({name: "accountNumber", value: null}, '', '', '');
        this.props.onChangeFunction({name: "clientNumber", value: null}, '', '', '');

        this.setState({
            studentCard: !this.state.studentCard,
            ...this.props.data.contaCaixaM,
            contaCaixaM: this.props.data.contaCaixaM
        });

        this.handleChange();
    }

    onresetCardStudent(){
        this.setState({
            ...this.state,
            studentCard: false,
        });
    }

    back() {
        this.props.data.studentCard = this.state.studentCard;
        this.props.prevStep('product', this.props.data);
    }

    continue() {
        this.props.data.studentCard = this.state.studentCard;
        this.props.nextStep('product', this.props.data);
    }

    validateFieds(value, regex){
        if(value === null || value === ""){return false;}
        if(regex.test(value)){return true;}
        return false;
    }


    render() {
        const { loading } = this.state;
        const { activeStep, onChangeFunction, hasPreviousStep, hasNextStep, globalResources, formResources, data, personData, loadingData } = this.props;
        const inputsInvalid =  (!Math.min.apply(
            null,[!this.validateFieds(this.props.personData.accountNumber,
            this.regexInputAccountNumber), !this.validateFieds(this.props.personData.cardNumber,this.regexInputCardNumber),
            !this.validateFieds(this.props.personData.clientNumber, this.regexInputNumberClient)]
            ) && this.props.data.ValidUseExistingAccount);
        
        const inputvalidClientNumber = personData.clientNumber === null? inputsInvalid : this.validateFieds(personData.clientNumber, this.regexInputNumberClient);
        const inputvalidNumberAccount = personData.accountNumber === null? inputsInvalid : this.validateFieds(personData.accountNumber, this.regexInputAccountNumber);
        const inputvalidNumberCard = personData.cardNumber === null? inputsInvalid : this.validateFieds(personData.cardNumber, this.regexInputCardNumber);

        const validateInputs = inputvalidClientNumber && inputvalidNumberAccount && inputvalidNumberCard

        var buttonDisable = true;
        
        if(this.state.studentCard){
            buttonDisable = false; 
        } else if (this.props.data.useExistingAccount && validateInputs && this.props.data.ValidUseExistingAccount) {
            buttonDisable = false
        }else if(this.props.data.contaCaixaM){
            buttonDisable = false
        }else{
            buttonDisable = true;
        }

        if (loadingData) {
            return (
                <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
                    <Loading loadingText={globalResources.LoadingPageContentText} />
                </Container>
            );
        }

        return (
            <Container>
                <Row className="main-form__header">
                    <Col>
                        <h1 className="sr-only">{formResources.Step3Title}</h1>
                        <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                    </Col>
                </Row>
                <ClientQuestionField
                    formResources={formResources}
                    onChangeFunction={onChangeFunction}
                    resetCardStudent = {this.onresetCardStudent}
                    data={data}
                />
                {data.useExistingAccount === undefined ? "" : 
                    <Row>
                        <Col>
                            <Form className="needs-validation" noValidate>
                                <FormGroup tag="fieldset" className="mb-0">
                                    <legend className="sr-only">{formResources.ConfirmYourMembershipLegend}</legend>
                                    <Row>
                                        {!data.useExistingAccount ? 
                                            <Col>
                                                <FormGroup className="btn btn-primary btn-primary__tradius d-block p-0 m-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        <Label check className="p-4 pr-5 cursor-pointer">
                                                            <Input type="checkbox" id="contaCaixaM1" name="contaCaixaM" defaultValue={false} checked={data.contaCaixaM} onClick={this.handleShowModalContaM} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0 text-white">{formResources.FormLabelWantToJoin} <strong>Conta Caixa M <TooltipItem key="FormTooltipContaCaixaM" id="FormTooltipContaCaixaM" invertColor={true} text={formResources.FormTooltipContaCaixaM} /> - {formResources.FormLabelHigherEducationAccessSeveralSdvantages} </strong> {formResources.FormLabelHigherEducationAccessSeveralSdvantages2} <strong>{formResources.FormLabelHigherEducationAccessSeveralSdvantages3}</strong></div>
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                                <FormGroup className="sub-btnbox d-block mb-5 p-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        <p className="sub-btnbox__title h5 pt-4 pr-4 pl-4 cursor-pointer" onClick={this.handleTermsLinkModalContaMClicked}><strong>{formResources.FormLabelInfoDocs}</strong></p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms1}</p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms2}</p>
                                                        <Label check className="pt-3 pr-4 pb-4 pl-4 cursor-pointer">
                                                            <Input type="checkbox" id="contaCaixaM2" name="contaCaixaM" defaultValue={false} checked={data.contaCaixaM} onClick={this.handleShowModalContaM} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0"><strong>{formResources.FormLabelDeclareKnowledge}</strong></div>
                                                        </Label>
                                                    </FormGroup>
                                                    <span id="contaCaixaMCollapse" />
                                                </FormGroup>
                                            </Col>
                                        : 
                                            <Col>
                                                <FormGroup className="btn btn-primary btn-primary__tradius d-block p-0 m-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        <Label check className="p-4 pr-5 cursor-pointer">
                                                        <Input type="checkbox" id="contaCaixaM5" name="contaCaixaM" defaultValue={false} checked={this.props.data.ValidUseExistingAccount} onClick={this.handleShowModalUseAccount} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0 text-white">{formResources.FormLabelWantToJoin} <strong>Conta Caixa M <TooltipItem key="FormTooltipContaCaixaM" id="FormTooltipContaCaixaM" invertColor={true} text={formResources.FormTooltipContaCaixaM} /> - {formResources.FormLabelHigherEducationAccessSeveralSdvantages}, </strong> com a tua conta {formResources.FormLabelHigherEducationAccessSeveralSdvantages2} <strong>{formResources.FormLabelHigherEducationAccessSeveralSdvantages3}</strong> </div>
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                                <FormGroup className="sub-btnbox d-block mb-5 p-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        
                                                        <div className='d-flex flex-wrap pt-4 pr-4 pl-4' style={{columnGap:"10px"}}>
                                                            <FormGroup className="col-sm-4 col-lg-3 pl-0 pr-4">
                                                                <Label for="numberClient" className="h6">
                                                                   {formResources.ClientNumberText}
                                                                </Label>
                                                                <NumberOfClientField formResources={formResources} data={personData} valid={inputvalidClientNumber} onChangeFunction={onChangeFunction}  />
                                                            </FormGroup>
                                                            <FormGroup className="col-sm-4 col-lg-3 pl-0 pr-4">
                                                                <Label for="numberClient" className="h6">
                                                                    {formResources.AccountNumberText}
                                                                </Label>
                                                                <AccountNumberField formResources={formResources} data={personData} valid={inputvalidNumberAccount} onChangeFunction={onChangeFunction} />
                                                            </FormGroup>
                                                            <FormGroup className="col-sm-4 col-lg-3 pl-0 pr-4">
                                                                <Label for="numberClient" className="h6">
                                                                    {formResources.CardNumberText}
                                                                </Label>
                                                                <CardNumberField formResources={formResources} data={personData} valid={inputvalidNumberCard} onChangeFunction={onChangeFunction} />
                                                            </FormGroup>
                                                            
                                                            <div className={!inputsInvalid? 'is-invalid': '' }></div>
                                                            <div className="invalid-feedback">
                                                                {formResources.ErrorTextFieldsClient}
                                                            </div> 
                                                        </div>

                                                        <p className="sub-btnbox__title h5 pt-4 pr-4 pl-4 cursor-pointer" onClick={this.handleTermsLinkModalContaMClicked}><strong>{formResources.FormLabelInfoDocs}</strong></p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms1}</p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms2}</p>
                                                        <Label check className="pt-3 pr-4 pb-4 pl-4 cursor-pointer">
                                                            <Input type="checkbox" id="UseExistingAccountM1" name="UseExistingAccount" defaultValue={false} checked={this.props.data.ValidUseExistingAccount} onClick={this.handleShowModalUseAccount} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0"><strong>{formResources.FormLabelDeclareKnowledge}</strong></div>
                                                        </Label>
                                                    </FormGroup>
                                                    <span id="contaCaixaMCollapse"></span>
                                                </FormGroup>

                                            </Col>
                                        }
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div className="h4 mb-4 px-4" dangerouslySetInnerHTML={{ __html: formResources.MultiProductSolution }} />
                                        </Col>
                                    </Row>

                                    <CardCaixaIU formResources={formResources} />

                                    <CaixaDireta globalResources={globalResources} formResources={formResources} />

                                    <CardCaixaISIC formResources={formResources} />

                                    <UncontrolledCollapse toggler="#contaCaixaMCollapse" isOpen={data.contaCaixaM || data.ValidUseExistingAccount}>
                                        {/*<CardContinente globalResources={globalResources} formResources={formResources} data={data} onChangeFunction={onChangeFunction} />*/}
                                        <ContaPoupanca formResources={formResources} data={data} onChangeFunction={onChangeFunction} isChecked={true} onPoupancaTermsChanged={this.onPoupancaTermsChanged} handleTermsLinkModalContaPoupancaClicked={this.handleTermsLinkModalContaPoupancaClicked}/>
                                    </UncontrolledCollapse>
                                

                                    <Row>
                                        {!data.useExistingAccount ? 
                                            <Col>
                                                <FormGroup className="btn btn-primary btn-primary__tradius d-block p-0 m-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        <Label check className="p-4 pr-5 cursor-pointer">
                                                            <Input type="checkbox" id="contaCaixaM3" name="contaCaixaM" defaultValue={false} checked={data.contaCaixaM} onClick={this.handleShowModalContaM} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0 text-white">{formResources.FormLabelWantToJoin} <strong>Conta Caixa M <TooltipItem key="FormTooltipContaCaixaM" id="FormTooltipContaCaixaM" invertColor={true} text={formResources.FormTooltipContaCaixaM} /> - {formResources.FormLabelHigherEducationAccessSeveralSdvantages} </strong> {formResources.FormLabelHigherEducationAccessSeveralSdvantages2} <strong>{formResources.FormLabelHigherEducationAccessSeveralSdvantages3}</strong></div>
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                                <FormGroup className="sub-btnbox d-block mb-5 p-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        <p className="sub-btnbox__title h5 pt-4 pr-4 pl-4 cursor-pointer" onClick={this.handleTermsLinkModalContaMClicked}><strong>{formResources.FormLabelInfoDocs}</strong></p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms1}</p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms2}</p>
                                                        <Label check className="pt-3 pr-4 pb-4 pl-4 cursor-pointer">
                                                            <Input type="checkbox" id="contaCaixaM4" name="contaCaixaM" defaultValue={false} checked={data.contaCaixaM} onClick={this.handleShowModalContaM} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0"><strong>{formResources.FormLabelDeclareKnowledge}</strong></div>
                                                        </Label>
                                                    </FormGroup>
                                                    <span id="contaCaixaMCollapse" />
                                                </FormGroup>
                                            </Col> 
                                        : 
                                        
                                            <Col>
                                                <FormGroup className="btn btn-primary btn-primary__tradius d-block p-0 m-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        <Label check className="p-4 pr-5 cursor-pointer">
                                                        <Input type="checkbox" id="contaCaixaM7" name="contaCaixaM" defaultValue={false} checked={this.props.data.ValidUseExistingAccount} onClick={this.handleShowModalUseAccount} onChange={this.handleChange} required />
                                                        <div className="form-check-text h5 mb-0 text-white">{formResources.FormLabelWantToJoin} <strong>Conta Caixa M <TooltipItem key="FormTooltipContaCaixaM" id="FormTooltipContaCaixaM" invertColor={true} text={formResources.FormTooltipContaCaixaM} /> - {formResources.FormLabelHigherEducationAccessSeveralSdvantages}, </strong> com a tua conta {formResources.FormLabelHigherEducationAccessSeveralSdvantages2} <strong>{formResources.FormLabelHigherEducationAccessSeveralSdvantages3}</strong> </div>
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                                <FormGroup className="sub-btnbox d-block mb-5 p-0 text-left">
                                                    <FormGroup check className="form-check__big">
                                                        
                                                        <div className='d-flex flex-wrap pt-4 pr-4 pl-4' style={{columnGap:"10px"}}>
                                                            <FormGroup className="col-sm-4 col-lg-3 pl-0 pr-4">
                                                                <Label for="numberClient" className="h6">
                                                                    {formResources.ClientNumberText}
                                                                </Label>
                                                                <NumberOfClientField formResources={formResources} data={personData} valid={inputvalidClientNumber} onChangeFunction={onChangeFunction} />
                                                            </FormGroup>
                                                            <FormGroup className="col-sm-4 col-lg-3 pl-0 pr-4">
                                                                <Label for="numberClient" className="h6">
                                                                    {formResources.AccountNumberText}
                                                                </Label>
                                                                <AccountNumberField formResources={formResources} data={personData} valid={inputvalidNumberAccount} onChangeFunction={onChangeFunction}/>
                                                            </FormGroup>
                                                            <FormGroup className="col-sm-4 col-lg-3 pl-0 pr-4">
                                                                <Label for="numberClient" className="h6">
                                                                    {formResources.CardNumberText}
                                                                </Label>
                                                                <CardNumberField formResources={formResources} data={personData} valid={inputvalidNumberCard} onChangeFunction={onChangeFunction}/>
                                                            </FormGroup>
                                                            
                                                            <div className={!inputsInvalid? 'is-invalid': '' }></div>
                                                            <div className="invalid-feedback">
                                                                {formResources.ErrorTextFieldsClient}
                                                            </div> 
                                                        </div>

                                                        <p className="sub-btnbox__title h5 pt-4 pr-4 pl-4 cursor-pointer" onClick={this.handleShowModalUseAccount}><strong>{formResources.FormLabelInfoDocs}</strong></p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms1}</p>
                                                        <p className="sub-btnbox__note h6 pl-4 pr-4 mb-0">{formResources.FormLabelTerms2}</p>
                                                        <Label check className="pt-3 pr-4 pb-4 pl-4 cursor-pointer">
                                                            <Input type="checkbox" id="UseExistingAccountM2" name="UseExistingAccount" defaultValue={false} checked={this.props.data.ValidUseExistingAccount} onClick={this.handleShowModalContaM} onChange={this.handleChange} required />
                                                            <div className="form-check-text h5 mb-0"><strong>{formResources.FormLabelDeclareKnowledge}</strong></div>
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>

                                    <section className="product__card-student mb-5 pb-4">
                                        <Row>
                                            <Col>
                                                <FormGroup className="btn btn-link btn-block btn-cards text-left text-dark text-decoration-none">
                                                    <FormGroup check className="form-check__big">
                                                        <Label check className="cursor-pointer">
                                                            <Input type="checkbox" id="studentCard" name="studentCard" defaultValue={false} checked={this.state.studentCard} onClick={this.handleStudentCardChange} onChange={this.handleChange} required />
                                                            <div className="form-check-text h6 text-uppercase"><strong>{formResources.CardStudentNonBankingTitle}</strong></div>
                                                            <p className="ml-5 mb-0">{formResources.CardStudentNonBankingDescription}</p>
                                                        </Label>
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </section>
                                </FormGroup>
                                <MainFormNavigation prevStep={this.back} nextStep={this.continue} hasPreviousStep={hasPreviousStep} hasNextStep={hasNextStep} prevText={globalResources.NavBackText} nextText={globalResources.NavForwardText} buttonDisable={buttonDisable} loading={loading} loadingText={globalResources.LoadingPageText} />
                            </Form>
                        </Col>
                    </Row >
                }

                <ModalForm
                    isChecked={this.state.isModalChecked}
                    show={this.state.show}
                    title={formResources.ModalHeader}
                    modalInputCaller={this.state.modalInputCaller}
                    label={formResources.ModalLabel}
                    onClick={this.handleClose}
                    onHide={this.handleClose}
                />
                
            </Container>
        );
    }
}
