import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import './CreateCard.scss';

export class CreateCard extends Component {
    static displayName = CreateCard.name;
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            url: 'Authentication'
        };
    }

    async componentDidMount() {
        await authService.isAuthenticated().then((response) => {
            response && this.setState({ url: 'MainForm' });
        });
    }

    render() {
        const { globalResources } = this.props;

        return (
            <section className="create-card">
                <Container>
                    <Row className="center-row my-5 align-items-center">
                        <Col xs="auto">
                            <h2 id="aderir" className="h4 mb-3 mb-md-0 text-center text-md-right anchor-position">{globalResources.HPCreatCardTitle}</h2>
                        </Col>
                        <Col xs="auto">
                            <Link to={`/${this.state.url}`} className="btn btn-primary text-uppercase px-5 py-2">{globalResources.HPCreatCardCTAText}</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
