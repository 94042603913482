import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Alert, CustomInput } from 'reactstrap';
import { MainFormNavInfo } from './MainFormNavInfo';
import { MainFormNavigation } from './MainFormNavigation';
import Loading from '../Global/Loading';
import UploadField from '../Fields/UploadField';
import { BusinessRelationshipField } from '../Fields/BusinessRelationshipField';
import { SourceFundsField } from '../Fields/SourceFundsField';


export class MainFormStep4 extends Component {
    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            loading: false,
            errors: [],
            errorMessage: '',
            fileSizes: {}
        };

        this.submitForm = this.submitForm.bind(this);
        this.continue = this.continue.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        const {referenceData} = this.props;
        this.setState({
            ...this.state, 
            purposeBusinessRelationship: referenceData.purposeBusinessRelationship,
            sourceFunds: referenceData.sourceFunds,
            otherFunds: undefined
        });

        this.props.checkFormValidate();
        window.scrollTo(0, 0);
    }

    submitForm(e) {
        e.preventDefault();

        this.setState({ loading: true });
        this.submitFormPost();
    }

    async submitFormPost() {
        const { person, consentData, product, summary } = this.props.fullDate;
        var formData = new FormData();
        formData.append('Data', JSON.stringify({summary, person, product,consentData}));
        formData.append('files', consentData.photographyFile, "Photo");
        if (product.contaCaixaM || product.ValidUseExistingAccount) {
            formData.append('files', consentData.documentIDCoverFile, "IdCardFront");
            formData.append('files', consentData.documentIDBackFile, "IdCardBack");
            formData.append('files', consentData.addressFile, "Address");
            person.secondAddress && formData.append('files', consentData.secondAddressFile, "FiscalAddress");
            person.identificationTypeCode !== '801' && formData.append('files', consentData.fiscalNumberCardFile, "FiscalCard");
        }

        var headers = new Headers({});
        const response = await fetch('/Form/GetPdf', {
            method: 'POST',
            headers: headers,
            body: formData
        });
        const result = await response.json();

        if (response.status === 200 && response.ok) {
            if (!result.error) {
                this.props.handleChange('', '', '', { name: 'linkPdf', value: result.value, checked: false, files: undefined });
                this.continue();
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    errors: true,
                    errorMessage: this.props.globalResources.ErrorCreatingPDF,
                    loading: false
                }));

                window.scrollTo(0, 0);
            }
        } else {
            this.setState(prevState => ({
                ...prevState,
                errors: this.props.globalResources.ErrorGeneric,
                loading: false
            }));

            window.scrollTo(0, 0);
        }
    }

    back() {
        this.props.prevStep('consentData', this.props.data);
    }

    continue() {
        this.props.nextStep('consentData', this.props.data);
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

   
    render() {
        const { loading, errorMessage } = this.state;
        const { activeStep, handleChange, checkFormValidate, hasPreviousStep, hasNextStep, globalResources, formResources, data, step2Values, loadingData, isValid, fullDate } = this.props;

        const documentDescriptionPhotoUpload = formResources.DocumentDescriptionPhotoUpload;
        const photoUploadMessage = data.fileSizes && data.fileSizes.photoMaxUploadSize ? documentDescriptionPhotoUpload.replace('[PHOTOSIZE]', this.formatBytes(data.fileSizes.photoMaxUploadSize)) : documentDescriptionPhotoUpload;

        const documentDescriptionFilesUpload = formResources.DocumentDescriptionFilesUpload;
        const fileUploadMessage = data.fileSizes && data.fileSizes.fileMaxUploadSize ? documentDescriptionFilesUpload.replace('[FILESIZE]', this.formatBytes(data.fileSizes.fileMaxUploadSize)) : documentDescriptionFilesUpload;

        if (loadingData) {
            return (
                <Container className="d-flex flex-column h-100 justify-content-center align-items-center">
                    <Loading loadingText={globalResources.LoadingPageContentText} />
                </Container>
            );
        }

        return (
            <Container>
                <Row className="main-form__header">
                    <Col>
                        <h1 className="sr-only">{formResources.Step4Title}</h1>

                        <MainFormNavInfo activeStep={activeStep} formResources={formResources} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="9" xl="8">
                        <div>
                            <h2>{formResources.Step4Title}</h2>

                            <p>{formResources.DocumentDescription}<br />
                                {photoUploadMessage}
                                {fullDate.product.contaCaixaM && <><br />{fileUploadMessage}</>}
                            </p>
                        </div>

                        <Form className="needs-validation" noValidate>
                            <FormGroup tag="fieldset" className="mb-0">
                                <legend className="sr-only">{formResources.DocumentLegend}</legend>

                                {errorMessage &&
                                    <Alert key={errorMessage} color="danger"><div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert>
                                }

                                <div className="bg-light ml-n2 ml-sm-n4 mr-n2 mr-sm-n4 mr-lg-0 mb-5 px-4 pt-3 pb-1">
                                    <FormGroup className="mb-5">
                                        <UploadField inputName="photographyFile" fileMaxSize={data.fileSizes.photoMaxUploadSize} inputLabel={formResources.FormLabelPhotographyFile} renderThumb={true} defaultValue={data.photographyFile} onChangeFunction={handleChange} onlyImages={true} formCheck={checkFormValidate} errorMessages={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorUploadInvalid, invalidSize: formResources.FormErrorUploadInvalidSize }} formResources={{ ...formResources }} required={true} />
                                    </FormGroup>
                                    {(fullDate.product.contaCaixaM || fullDate.product.ValidUseExistingAccount) && <>
                                        <FormGroup tag="fieldset" className="mb-4">
                                            <legend className="form-label text-uppercase">{formResources.IdentityDocument}</legend>

                                            <Row>
                                                <FormGroup className="col-sm-6 col-md-4">
                                                    <UploadField inputName="documentIDCoverFile" fileMaxSize={data.fileSizes.fileMaxUploadSize} inputLabel={formResources.FormLabelDocumentIDCoverFile} renderThumb={false} defaultValue={data.documentIDCoverFile} onChangeFunction={handleChange} formCheck={checkFormValidate} errorMessages={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorUploadInvalid, invalidSize: formResources.FormErrorUploadInvalidSize }} formResources={{ ...formResources }} required={true} />
                                                </FormGroup>
                                                <FormGroup className="col-sm-6 col-md-4">
                                                    <UploadField inputName="documentIDBackFile" fileMaxSize={data.fileSizes.fileMaxUploadSize} inputLabel={formResources.FormLabelDocumentIDBackFile} renderThumb={false} defaultValue={data.documentIDBackFile} onChangeFunction={handleChange} formCheck={checkFormValidate} errorMessages={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorUploadInvalid, invalidSize: formResources.FormErrorUploadInvalidSize }} formResources={{ ...formResources }} required={true} />
                                                </FormGroup>
                                            </Row>
                                            {step2Values.identificationTypeCode !== '801' &&
                                                <Row>
                                                    <FormGroup className="col-sm-6 col-md-4">
                                                        <UploadField inputName="fiscalNumberCardFile" fileMaxSize={data.fileSizes.fileMaxUploadSize} inputLabel={formResources.FormLabelFiscalNumberCardFile} renderThumb={false} defaultValue={data.fiscalNumberCardFile} onChangeFunction={handleChange} formCheck={checkFormValidate} errorMessages={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorUploadInvalid, invalidSize: formResources.FormErrorUploadInvalidSize }} formResources={{ ...formResources }} required={true} />
                                                    </FormGroup>
                                                </Row>
                                            }
                                        </FormGroup>
                                        <FormGroup tag="fieldset" className="mb-0">
                                            <legend className="form-label text-uppercase">{formResources.ProofOfAddressLegend}</legend>

                                            <Row>
                                                <FormGroup className="col-sm-6 col-md-4">
                                                    <UploadField inputName="addressFile" fileMaxSize={data.fileSizes.fileMaxUploadSize} inputLabel={formResources.FormLabelAddressFile} renderThumb={false} defaultValue={data.addressFile} onChangeFunction={handleChange} formCheck={checkFormValidate} errorMessages={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorUploadInvalid, invalidSize: formResources.FormErrorUploadInvalidSize }} formResources={{ ...formResources }} required={true} />
                                                </FormGroup>
                                                {step2Values.secondAddress && <FormGroup className="col-sm-6 col-md-4">
                                                    <UploadField inputName="secondAddressFile" fileMaxSize={data.fileSizes.fileMaxUploadSize} inputLabel={formResources.FormLabelSecondAddressFile} renderThumb={false} defaultValue={data.secondAddressFile} onChangeFunction={handleChange} formCheck={checkFormValidate} errorMessages={{ empty: formResources.FormErrorFieldEmpty, invalid: formResources.FormErrorUploadInvalid, invalidSize: formResources.FormErrorUploadInvalidSize }} formResources={{ ...formResources }} required={true} />
                                                </FormGroup>
                                                }
                                            </Row>
                                        </FormGroup>
                                    </>}
                                </div>
                                {(fullDate.product.contaCaixaM || fullDate.product.ValidUseExistingAccount) && <>
                                    <FormGroup>
                                        <Row className="col-6 col-md-6">
                                            <Col className="pl-0">
                                                <BusinessRelationshipField formResources={formResources} data={step2Values} options={this.state.purposeBusinessRelationship} onChange={handleChange}/>
                                            </Col>
                                        </Row>
                                        <Row className="col-6 col-md-6" >
                                            <Col className="pl-0">
                                                <SourceFundsField formResources={formResources} data={step2Values} options={this.state.sourceFunds} onChange={handleChange}/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </>}

                                <FormGroup tag="fieldset">
                                    <legend className="sr-only">{formResources.PersonalDataAuthorizationLegend}</legend>

                                    <Row>
                                        <FormGroup className="col">
                                            <FormGroup tag="fieldset">
                                                <legend>{formResources.FormLabelDirectMarketing}*</legend>

                                                <FormGroup className="mb-0">
                                                    <div className="d-flex">
                                                        
                                                        <CustomInput type="radio" id="rgpAgrement1" name="rgpAgrement" label="Sim" value={true} checked={data.rgpAgrement === "true" ? true: false} onChange={handleChange} required />
                                                        <CustomInput type="radio" id="rgpAgrement2" name="rgpAgrement" label="Não" value={false} checked={data.rgpAgrement === "false" ? true: false} onChange={handleChange} required />
                                                    </div>
                                                </FormGroup>
                                            </FormGroup>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup className="col">
                                            <FormGroup tag="fieldset">
                                                <legend>{formResources.FormLabelCampaignsEvents}*</legend>

                                                <FormGroup className="mb-0">
                                                    <div className="d-flex">
                                                        <CustomInput type="radio" id="rgpdAgreementCampaignsAndEvents1" name="rgpdAgreementCampaignsAndEvents" label="Sim" value={true} checked={data.rgpdAgreementCampaignsAndEvents === "true" ? true: false} onChange={handleChange} required />
                                                        <CustomInput type="radio" id="rgpdAgreementCampaignsAndEvents2" name="rgpdAgreementCampaignsAndEvents" label="Não" value={false} checked={data.rgpdAgreementCampaignsAndEvents === "false" ? true: false} onChange={handleChange} required />
                                                    </div>
                                                </FormGroup>
                                            </FormGroup>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup className="col">
                                            <FormGroup tag="fieldset">
                                                <legend>{formResources.FormLabelAgreementLegalDocumentation}*</legend>

                                                <FormGroup className="mb-0">
                                                    <div className="d-flex">
                                                        <CustomInput type="radio" id="agreementLegalDocumentation1" name="agreementLegalDocumentation" label="Sim" value={true} checked={data.agreementLegalDocumentation === "true" ? true: false} onChange={handleChange} required />
                                                        <CustomInput type="radio" id="agreementLegalDocumentation2" name="agreementLegalDocumentation" label="Não" value={false} checked={data.agreementLegalDocumentation === "false" ? true: false} onChange={handleChange} required />
                                                    </div>
                                                </FormGroup>
                                            </FormGroup>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <Col dangerouslySetInnerHTML={{ __html: formResources.TookNoticeDescription }} />
                                    </Row>
                                </FormGroup>
                            </FormGroup>

                            <MainFormNavigation prevStep={this.back} nextStep={this.continue} hasPreviousStep={hasPreviousStep} hasNextStep={hasNextStep} prevText={globalResources.NavBackText} nextText={globalResources.NavForwardText} loading={loading} loadingText={globalResources.LoadingPageText} buttonDisable={!isValid} onClickFunction={this.submitForm} />
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}