import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Loading from '../Global/Loading';
import PropTypes from 'prop-types';

export class MainFormNavigation extends Component {
    constructor(props) {
        super(props);

        this.props = props;

        this.continue = this.continue.bind(this);
        this.back = this.back.bind(this);
    }

    continue(event) {
        event.preventDefault();
        this.props.nextStep();
    }

    back(event) {
        event.preventDefault();
        this.props.prevStep();
    }

    render() {
        const { prevText, nextText, hasPreviousStep, hasNextStep, lastStep, buttonDisable, onClickFunction, loading, loadingText } = this.props;
        var clickButton;

        if (onClickFunction) {
            clickButton = onClickFunction;
        } else {
            clickButton = this.continue;
        }

        return (
            <Row>
                {hasNextStep &&
                    <Col className={`col-8${hasPreviousStep ? ' text-right' : ''} order-last align-self-center`}>
                        {loading
                            ? <Loading loadingText={loadingText} />
                            : <Button
                                type="submit"
                                color="primary"
                                className="text-uppercase px-4 py-2"
                                onClick={clickButton}
                                disabled={buttonDisable}
                            >
                                {nextText ? nextText : ''}
                                {!lastStep && <span aria-hidden="true">&nbsp;&gt;</span>}
                            </Button>
                        }
                    </Col>
                }
                {hasPreviousStep &&
                    <Col className="col-4 order-first align-self-center">
                        <Button
                            type="button"
                            color="link"
                            onClick={this.back}
                        >
                            <span aria-hidden="true">&lt;&nbsp;</span>
                            {prevText ? prevText : ''}
                        </Button>
                    </Col>
                }
            </Row>
        );
    }
}

MainFormNavigation.propTypes = {
    prevStep: PropTypes.func,
    nextStep: PropTypes.func
};