import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ImgCardCaixaIUOnly from '../../img/content/card-caixa-iu_only.png';
import './Banner.scss';

export class Banner extends Component {
    static displayName = Banner.name;

    render() {
        const { globalResources } = this.props;

        return (
            <section className="banner">
                <Container>
                    <Row className="pt-4 py-lg-4 align-items-center">
                        <Col lg="4">
                            <h1 className="mb-3">{globalResources.HPBannerTitle}</h1>
                            <div className="lead mb-4" dangerouslySetInnerHTML={{ __html: globalResources.HPBannerLead }} />

                            <div className='d-flex justify-content-center'>
                                <p className="d-flex justify-content-center justify-content-lg-start p_btnplay">
                                    <a href="#vantagens" target="_self" className="btn btn-outline-primary btn-banner text-uppercase py-2 w-100 px-5">{globalResources.HPBannerCTAText}</a>
                                </p>

                                <p className="d-flex justify-content-center justify-content-lg-start ml-3 p_btnplay">
                                    <a href="https://www.cgd.pt/Particulares/A-sua-Medida/Universitarios/Pages/Universitarios-CaixaIU.aspx#mediaplayer" target="_blank" rel="noopener noreferrer" className="d-flex btn btn-outline-primary btn-banner text-uppercase px-5 py-2">
                                        vê o video
                                        <span className="btnplay"></span>
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col lg="8" className="text-center text-lg-right">
                            <p><img className="img" src={ImgCardCaixaIUOnly} alt="" /></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
